<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                 Closed tickets
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Closed tickets
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="2">
                  <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.date_from"
                          label="View from date"
                          prepend-icon=""
                          prepend-inner-icon="mdi-calendar"
                          v-on:keyup.enter="searchSupportTicket()"
                          @input="search.date_from = $event !== null ? $event : ''"
                          :error="checkDateFrom"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                          clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.date_from"
                        no-title
                        @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                  <!--                  <span v-if="checkDateFrom" class="text-danger">This information is required !!</span>-->
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.date_to"
                          label="View up to date"
                          prepend-icon=""
                          prepend-inner-icon="mdi-calendar"
                          v-on:keyup.enter="searchSupportTicket()"
                          :error="checkDateTo"
                          @input="search.date_to = $event !== null ? $event : ''"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                          clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.date_to"
                        no-title
                        @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                  <!--                  <span v-if="checkDateTo" class="text-danger">This information is required !!</span>-->
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select
                      label="Type"
                      v-model="search.type"
                      :items="types"
                      item-text="name"
                      item-value="value"
                      @change="setType"
                      outlined
                      dense
                      @input="search.type = $event !== null ? $event : ''"
                      v-on:keyup.enter="searchSupportTicket()"
                      return-object
                      clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" md="2">
                  <v-select
                      :items="status"
                      v-model="search.is_active"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      outlined
                      clearable
                      v-on:keyup.enter="searchSupportTicket()"
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                      label="Search by email, name or phone"
                      v-model="search.user_info"
                      outlined
                      dense
                      @input="search.user_info = $event !== null ? $event : ''"
                      v-on:keyup.enter="searchSupportTicket()"
                      clearable
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                      label="Enrolment key"
                      v-model="search.enrol_key"
                      outlined
                      dense
                      @input="search.enrol_key = $event !== null ? $event : ''"
                      v-on:keyup.enter="searchSupportTicket()"
                      clearable
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2" v-if="currentUser.access_type == 'federal'">
                  <v-autocomplete
                      label="SCORE"
                      v-model="search.score_id"
                      :items="scores"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      @input="search.score_id = $event !== null ? $event : ''"
                      v-on:keyup.enter="searchSupportTicket()"
                      clearable>
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-select
                      label="Admin activity type"
                      v-model="search.is_read"
                      :items="read_types"
                      item-text="name"
                      item-value="value"
                      outlined
                      dense
                      @input="search.is_read = $event !== null ? $event : ''"
                      v-on:keyup.enter="searchSupportTicket()"
                      clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                      label="Assigned to"
                      v-model="search.assignedTo"
                      outlined
                      dense
                      @input="search.assignedTo = $event !== null ? $event : ''"
                      clearable
                      v-on:keyup.enter="searchSupportTicket()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                      label="Assigned by"
                      v-model="search.assignedBy"
                      outlined
                      dense
                      @input="search.assignedBy = $event !== null ? $event : ''"
                      clearable
                      v-on:keyup.enter="searchSupportTicket()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2" v-if="currentUser.access_type == 'federal'">
                  <v-select
                      v-model="search.assigned_type"
                      :items="assignedTypes"
                      label="Access type"
                      item-text="name"
                      item-value="value"
                      outlined
                      clearable
                      v-on:keyup.enter="searchSupportTicket()"
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-menu
                      ref="menu"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.assigned_date"
                          label="Assigned date"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.assigned_date"
                        outlined
                        dense
                        no-title
                        @input="menu2 = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                      label="Closed by"
                      v-model="search.closed_by"
                      outlined
                      dense
                      @input="search.closed_by = $event !== null ? $event : ''"
                      clearable
                      v-on:keyup.enter="searchSupportTicket()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-menu
                      ref="menu"
                      v-model="menuClosed"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.closing_date"
                          label="Closing Date"
                          readonly
                          clearable
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.closing_date"
                        outlined
                        dense
                        no-title
                        @input="menuClosed = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" :md="currentUser &&currentUser.access_type == 'score' ? 12 : 8">
                  <v-btn
                      @click="searchSupportTicket()"
                      class="btn w-35 btn-primary float-right"
                      style="color: #fff"
                      :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>
            <div class="table-responsive">

              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table">
                <thead>
                <tr class="text-left">
                  <th class="px-3">Ref. No.</th>
                  <th class="px-3">Request</th>
                  <th class="px-3">Enroller</th>
                  <th class="px-3">Candidate</th>
                  <th class="px-3">Added date</th>
                  <th class="px-3">Assigned to</th>
                  <th class="px-3">Closed date</th>
                  <th class="px-3">Closed by</th>
                </tr>
                </thead>
                <tbody class="mt-5">
                <template>
                  <tr v-for="(item, index) in support_tickets" :key="index">
                    <td class="px-2">

                        <span class=" font-weight-bolder">{{ item.id }}</span>

                    </td>
                    <td class="px-2">
                      <a @click="viewSupportThread(item)" class="d-flex align-items-center">
                        <span class=" font-weight-bolder">{{ item.name }}</span>
                      </a>
                      <span class="badge badge-warning" v-if="item.type=='refund'">Refund</span>
                      <span class="badge badge-primary" v-else>{{ item.type }}</span>
                    </td>
                    <td class="px-3" style="white-space: pre-wrap;">
                        <a  @click="goToUser(item)" v-if="item.user!=''"> {{item.user ? item.user : '' }}</a>
                      <span v-else>NA</span>
                    </td>
                    <td class="px-3" style="white-space: pre-wrap;">
                      <a @click="goToCandidate(item.candidate_exam_key)">{{item.candidate ? `${item.candidate} \n ${item.candidate_exam_key}`: 'NA' }}</a>
                    </td>

                    <td class="px-3">
                       <span  class="text-dark-75 mb-1 mt-2 d-flex align-items-center">
                        {{ item.formatted_created_at ? item.formatted_created_at : '--'}}
                      </span>
                    </td>
                    <td  class="px-3">
                      <span class="fw-bold">   {{item.assigned_type_text}}</span>
                    </td>
                    <td  class="px-3">
                      <span class="fw-bold">{{item.formatted_closing_date ? item.formatted_closing_date : '--'}}</span>
                    </td>

                    <td  class="px-3">
                      <span class="fw-bold">{{item.closedBy ? item.closedBy : 'Enroller'}}</span>
                    </td>
                  </tr>
                  <tr v-if="support_tickets.length == 0">
                    <td colspan="10" class="text-center">
                      <strong>No Items added</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="support_tickets.length > 0"
                  class="pull-right mt-7"
                  @input="getAllCloseTicket"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  :disabled="loading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import SupportTicketService from "@/services/support/support-ticket/SupportTicketService";
import ScoreService from "@/services/score/score/ScoreService";

const supportTicket=new SupportTicketService();
const score=new ScoreService();
export default {
  data() {
    return {
      scores :[],
      support_tickets:[],
      total: null,
      perPage: null,
      page: null,
      loading: false,
      menu: false,
      menu1: false,
      menu2: false,
      menuClosed: false,
      currentUser: {},
      search:{
        name: '',
        type: '',
        is_closed: 1,
        closed_by: '',
        closing_date: '',
        is_active: '',
      },
      assignedTypes: [
        {name: 'Federal', value: 'federal'},
        {name: 'Score', value: 'score'}
      ],
      status: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
      read_types: [
        {name: 'Opened', value: '1'},
        {name: 'Not opened', value: '0'}
      ],
      types: [
        {name: 'General', value: 'general'},
        {name: 'Extension', value: 'extension'},
        {name: 'Refund', value: 'refund'},
        {name: 'Payment', value: 'payment'},
        {name: 'Appeal', value: 'appeal'},
        {name: 'Feedback', value: 'feedback'},
        {name: 'Shipment', value: 'shipment'},
        {name: 'Other', value: 'other'},
      ],
    }
  },
  computed: {
    checkDateFrom() {
      return this.search.date_to != '' && this.search.date_from == '';
    },
    checkDateTo() {
      return this.search.date_from != '' && this.search.date_to == '';
    }
  },
  methods:{

    getAllCloseTicket(){
      this.loading =  true;
      supportTicket
          .paginate(this.search,this.page)
          .then(response => {
            this.support_tickets=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading =  false;
          })
          .catch((err) => {});
    },
    searchSupportTicket(){
      this.getAllCloseTicket();
    },
    viewSupportThread(ticket){
      this.$router.push({
        name:'support-ticket-view',
        params:{supportTicketId: ticket.id}
      });
    },
    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    },
    getAllScore() {
      score
          .all()
          .then(response => {
             this.scores = response.data.scores;
          })
          .catch((err) => {
              this.$snotify.error("Oops something went wrong");
          });
    },
    setType() {
      let type = this.search.type;
      if(type) {
        this.type = type.value;
        this.search.type = type.value;
      }else {
        this.type = 'general';
      }
      this.getAllSupportTicket();
    },
    goToUser(support_ticket) {
      if(support_ticket.user_id) {
        if(support_ticket.user_type == 'account_holder') {
          this.$router.push({
            name: 'accountholder-edit',
            params: {accountholderId: support_ticket.user_id}
          });
        } else if(support_ticket.user_type == 'examiner') {
          this.$router.push({
            name: 'examiner-edit',
            params: {examinerId: support_ticket.user_id}
          });
        } else if(support_ticket.user_type == 'supervisor') {
          this.$router.push({
            name: 'supervisor-edit',
            params: {supervisorId: support_ticket.user_id}
          });
        } else {
          this.$router.push({
            name: 'online-marker-edit',
            params: {onlineMarkerId: support_ticket.user_id}
          });
        }
      }
    },

    goToCandidate(examKey){
      if(examKey) {
        this.$router.push({
          name: 'candidate-summary',
          params: {examKey: examKey}
        });
      }
    },
  },
  mounted() {
    this.getAllCloseTicket();
    this.getCurrentUser();
    this.getAllScore();
  }
}
</script>